import React from "react"
import Layout from "../../template/Layout"
import styled from "styled-components"
import Head from "../../head";
import { RESPONSIVE_STYLES, TABLET_STYLES, WRAPPER_STYLES, POLICY_STYLES } from "../../AppConst"
import { SpWrapper } from "../common/SpWrapper"
import { SectionTitle } from '../common/SectionTitle'
import Breadcrumbs, {
  Props as BreadcrumbsData,
} from "../common/Breadcrumbs"

/**
 * Style
 */
const Container = styled.div`
  ${WRAPPER_STYLES}
  ${TABLET_STYLES}
  box-sizing: border-box;
  max-width: 960px;
`
const BreadcrumbWrapper = styled.div`
  margin-top: 30px;
  margin-bottom: 96px;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    margin-top: 20px;
    margin-bottom: 56px;
  }
`

const Wrapper = styled.div`
  ${POLICY_STYLES}
  & li > ul, ol {
    margin: 0;
  }
  h4 {
    margin-bottom: 20px;
  }
`

/**
 * Component
 */

type Props = {
  breadcrumbsData: BreadcrumbsData["values"]
}

export const Personal: React.FC<Props> = ({
  breadcrumbsData,
}) => {
  return (
    <Layout sidebar={false} breadcrumbsData={breadcrumbsData}>
      <Head
        title="保有個人データの開示等に関する手続き | ネクストライブ株式会社"
        description="ネクストライブ株式会社の保有個人データの開示等に関する手続きを掲載しています。"
      />
      <Container>
        <SpWrapper>
          {
            // <BreadcrumbWrapper>
            //   <Breadcrumbs values={breadcrumbsData} />
            // </BreadcrumbWrapper>
          }
          <Wrapper>
            <SectionTitle title="Disclosure Of Personal Data" subTitle="保有個人データの開示等に関する手続き" />
            <p>
              当社が保有個人データに関する利用目的の通知、開示、訂正等、利用停止等及び
              第三者提供の停止（以下、「開示等」といいます。）をする場合の手続は以下のとおりとします。
            </p>
            <section>
              <h3>手続きの流れ</h3>
              <h4>
                ① 当社における保有個人データの開示等の請求窓口及び
                保有個人データの取扱いに関する苦情の相談窓口は以下のとおりです。
              </h4>
              <p>
                〒553-0003<br />
                大阪市福島区福島1-4-4 セントラル70 3F<br />
                ネクストライブ株式会社<br />
                電話番号 06-6147-2675<br />
                受付時間 月曜日〜金曜日（祝日、年末年始は除く）<br />
                <span className="reception_time">9時30分〜12時、13時〜16時30分</span>
              </p>
              <br/>
              <h4>② 請求・相談窓口への郵送</h4>
              <p>以下の書類を封入して①の窓口にご郵送ください。</p>
              <ul className="list_style_disc">
                <li>
                  <a href="/pdfs/request.pdf" download>保有個人データ開示等請求書</a>
                </li>
                <li>
                  本人確認書類
                  <ul>
                    <li className="list_style_upper-alpha">運転免許証、パスポート、在留カード、特別永住者証明書、個人番号カード（個人番号の記載された面は送付しないでください。）等の官公庁が発行した顔写真付き証明書の写し・・・１点のみ</li>
                    <li className="list_style_upper-alpha">健康保険被保険者証、年金手帳等の官公庁が発行した顔写真のない証明書の写し・・・2点の送付をお願いいたします。</li>
                  </ul>
                </li>
                <li>
                  手数料
                  <p style={{marginLeft: "0"}}>1件のご請求につき、次の手数料相当額の郵便切手を同封してください。</p>
                  <ul>
                    <li className="list_style_upper-alpha">開示請求<br />事務手数料（1件当たり）500円、郵便料金84円、簡易書留料金320円</li>
                    <li className="list_style_upper-alpha">利用目的の通知、訂正等、利用停止等請求<br />郵便料金84円、簡易書留料金320円</li>
                  </ul>
                </li>
              </ul>
              <br/>
              <h4>③ ご請求に応じられない場合</h4>
              <p>
                当社指定の請求書類をご郵送いただいていない場合や、ご本人の確認資料が不足している場合など、
                本手続に沿わないものと認められる場合には、請求を受理することができません。
                また、法令で定められる事由により、開示等の請求をお断りする場合があります。
              </p>
              <br/>
              <h4>④ 開示等・不開示等の決定通知</h4>
              <ol className="list_style_none">
                <li>
                  保有個人データ開示等決定通知書の通知
                  <p>
                    当社は、開示等の請求等のあった保有個人データの利用目的の通知をする旨決定したとき
                    又は全部又はその一部を除いた部分について開示、訂正等若しくは利用停止等
                    若しくは第三者提供の停止をする旨決定したときは、
                    請求者に対し、「保有個人データ開示等決定通知書」の送付により通知いたします。
                  </p>
                </li>
                <br/>
                <li>
                  保有個人データ不開示等決定通知書の通知
                  <p>
                    当社は、開示等の請求等のあった保有個人データの利用目的の通知をしない旨決定したとき
                    又は全部について開示、訂正等若しくは利用停止等若しくは第三者提供の停止をしない旨決定したときは、
                    請求者に対し、「保有個人データ不開示等決定通知書」の送付により通知いたします。
                  </p>
                </li>
              </ol>
            </section>
          </Wrapper>
        </SpWrapper>
      </Container>
    </Layout>
  )
}
