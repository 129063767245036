import React from "react"
import { Personal } from "../components/personal";

const breadcrumbsData: { text: string; path: string }[] = [
  { text: `保有個人データの開示等に関する手続き`, path: `personal` },
]

export default function PersonalPage() {
  return (
    <Personal breadcrumbsData={breadcrumbsData}/>
  )
}